@import "./colors";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

input[role="combobox"] {
  height: unset !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin: 0px !important;
}

.elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip {
  font-size: 14px !important;
  background-color: $white !important;
  border: 1px solid $grey-9;
  line-height: 1.4 !important;
  padding: 10px !important;
  box-shadow: 0px 2px 4px 0px $box-shadow-1;
  border-radius: 10px !important;
  color: $black !important;
  max-width: unset !important;

  .MuiTooltip-arrow {
    &:before {
      border: 1px solid $grey-9;
      background-color: $white;
    }
  }

  .order-details-tooltip-content-root-special {
    width: 820px !important;
    overflow: auto !important;

    .content-right-block,
    .content-left-block {
      width: 100%;
      min-width: 380px !important;
      overflow: auto;
    }

    .json-toggle {
      margin-bottom: 12px;

      .MuiCheckbox-root {
        padding: 0 10px !important;
      }
    }
  }
  .order-details-tooltip-content-root {
    max-height: 200px;
    overflow: auto;
    padding: 5px 10px;

    &.content-special {
      max-height: 400px;
      display: flex;
      justify-content: space-between;
      gap: 20px;

      .ouput-details-block {
        display: flex;
        justify-content: space-between;
      }

      .content-left,
      .content-right {
        width: 100%;
        min-width: 250px;
      }

      .content-left,
      .content-right {
        .input-inner-content {
          overflow: auto;
          max-height: 300px;
        }
      }

      .break-line {
        width: 1px;
        background-color: $grey-2;
      }

      .input-details-title {
        font-size: 15px;
        color: $blue-1;
        font-weight: bold;
        margin-bottom: 12px !important;
        display: block;
        text-align: center;
      }
    }

    .order-details-tooltip-content-title {
      font-size: 15px;
      color: $complementory;
      font-weight: bold;
      text-decoration: underline;
      margin-bottom: 10px;
    }

    .order-details-tooltip-content-list {
      margin: 0px;
      padding: 0px;
      list-style: none;

      .order-details-tooltip-content-list-item {
        padding: 10px 0px;
        border-bottom: 1px solid $grey-10;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0px;
        }

        &.no-bottom-line {
          border-bottom: none;
          padding: 0px;
        }

        .order-details-tooltip-content-list-item-root {
          margin: 5px 0px;

          > * {
            font-size: 14px;
            color: $grey-2;
          }

          .order-details-tooltip-content-list-item-key {
            font-weight: bold;
            color: $primary;
          }

          .order-details-tooltip-content-list-item-value {
            &.error {
              color: $error;
            }
          }
        }
      }
    }
  }
}

.order-details-tooltip-content-status {
  font-size: 15px;
  color: $complementory;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.root {
  padding: 10px;

  .dashboard-regenerate-api-key {
    margin-right: 10px;
  }
}

.btn {
  min-width: 100px;
  padding: 6px;
  box-sizing: border-box;
  height: 40px;
  background: $primary;
  border: none !important;
  color: $white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;

  &.danger {
    background: $error;
  }

  &.warning {
    background: $warning;
    color: $black;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: $grey-9;
  }
}

.custom-select__control--is-disabled {
  background-color: #c0c0c0 !important;
  border: 1px solid #656565 !important;
}

.custom-select__placeholder {
  color: #565656 !important;
}

.custom-select__single-value {
  color: #565656 !important;
}

input {
  height: 38px;
  box-sizing: border-box;
  border: 1px solid $grey-10;
  border-radius: 4px;
  width: 100%;
  font-size: 12px;
  padding: 5px 12px;
  outline: none;

  &.disabled {
    background-color: $grey-9 !important;
  }

  &::placeholder {
    color: $grey-1;
  }
}

.info-table {
  border-collapse: collapse;

  thead {
    tr {
      th {
        padding: 8px 0px;
        background-color: $primary;
        color: $white;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 5px 6px;
      }
    }
  }
}

.copy-details-root {
  display: flex;
  gap: 20px;
  align-items: center;
  max-width: 100%;
  word-break: break-all;
  padding: 20px 0px;
}

.cognito-copy-details-root {
  display: unset;
}

.copy-details-root > .cognito-token {
  display: flex;
  gap: 20px;
  align-items: center;
}

.copy-details-root > img,
.copy-details-root > .cognito-token > img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.copy-details-root > .active-copy,
.copy-details-root > .cognito-token > .active-copy {
  background-color: #999;
}
.copy-details-root-orders{
  display: flex;
  gap: 3%;
  justify-content: center;
 
}
.copy-details-root-orders > img {
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
}

.copy-details-root-orders > .active-copy {
  background-color: #999;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.container-card {
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

.btn-card {
  padding: 6px 10px;
}

.btn-card:hover,
.btn-email:hover {
  background: #04687c;
}

.validator-root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #7f7f7f;
}

.log-in {
  padding: 40px;
  width: 540px;
  border-radius: 0px;
  background-color: $white;
  font-family: sans-serif;
  border-radius: 8px;
  box-shadow: #00000033 0px 5px 15px;
}

.email-heading {
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin: 10px 0px;
}

.email-input {
  margin: 10px 0px;
  padding: 8px 16px;
  width: 100%;
  border-radius: 4px;
  border-width: 1px;
}

.email-input:focus-visible {
  outline-color: #000000;
}

.btn-email {
  width: 100%;
  margin: 10px 0px;
}

.error-msg {
  color: red;
}

.layout {
  width: 100%;
  height: 100vh;
  display: flex;
}

.layout-left {
  transition: width 100ms;
  height: 100vh;
  box-sizing: border-box;
  background-color: #edf4f6;
  margin-right: 1px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.layout-right {
  transition: width 100ms;
  height: 100vh;
  box-sizing: border-box;
}

.layout-left-section {
  width: 280px;
}

.layout-left-section-collapsed {
  width: 80px;
}

.layout-left-section-collapsed > .header-logo {
  height: 60px !important;
}

.layout-left-section-collapsed > .menus {
  height: calc(100vh - 120px);
}

.layout-left-section-collapsed > .header-logo > img {
  height: 60px;
}

.layout-left-section-collapsed > .layout-left-bottom {
  height: 60px;
}

.layout-left-section-collapsed > .layout-left-bottom > .act-as-main {
  display: none;
}

.layout-left-section-collapsed > .layout-left-bottom > .btn-card {
  min-width: fit-content;
}

.header-logo {
  height: 100px;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #ddd;
}

.header-logo > img {
  height: 100px;
}

.menus {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100vh - 250px);
}

.menu {
  padding: 10px 25px;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  gap: 10px;
}

.menu > label {
  cursor: pointer !important;
}

.menu > img {
  width: 25px;
  height: 25px;
}

.menu-selected {
  background: $primary;
  color: #fff;
}

.layout-left-bottom {
  height: 150px;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: end;

  .act-as-main {
    margin-bottom: 10px;
  }
}

.layout-left-bottom > label {
  font-size: 22px;
  font-weight: bold;
}

.layout-right-section {
  width: calc(100% - 280px);
}

.layout-right-section-collapsed {
  width: calc(100% - 80px);
}

.header {
  height: 48px;
  box-sizing: border-box;
  padding: 0px 10px;
  background-color: $primary;
  display: flex;
  justify-content: space-between;
}

.links {
  display: flex;
  gap: 20px;
  padding-right: 20px;
}

.links > .link {
  color: #fff;
  font-weight: 700;
}

.content-main {
  height: calc(100vh - 96px);
  box-sizing: border-box;
  padding: 10px;
  overflow: auto;
  background-color: #edf4f6;
}

.footer {
  height: 48px;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  background-color: $primary;
  color: $white;
  font-weight: bold;
  align-items: center;
}

.menu-collaps {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu-collaps > img {
  width: 20px;
}

.user-section {
  font-size: 16px;
  color: $white;
  font-weight: bold;
  padding-right: 20px;
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
    font-weight: normal;
  }
}

.table-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.table-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .filter-box {
    display: flex;
    align-items: center;
    gap: 20px;

    .carrier-filter {
      width: 250px;
    }

    .switch-layout {
      display: flex;
      align-items: center;

      .switch-label {
        font-size: 16px;
        font-weight: bold;

        &.complementory {
          color: $complementory;
        }

        &.blue-1 {
          color: $blue-1;
        }
      }
    }

    .search-box {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .column-selector {
    .MuiSelect-select {
      padding: 8px !important;
      background: white !important;
    }
  }
  .domain-selection {
    width: 250px;
  }

  .column-selector {
    width: 250px;
  }
}

.form-field-box {
  display: flex;
  flex-direction: column;

  > label {
    margin-bottom: 6px !important;
    font-size: 16px;
    font-weight: 600;

    .required {
      color: $error;
    }

    .note {
      font-size: 12px;
      font-style: italic;
      color: $grey-12;
    }
  }

  .d-error {
    margin-bottom: 0px !important;
    margin-top: 2px !important;
    font-weight: 600;
  }
}

.d-table {
  .table {
    border-collapse: separate;
    border-spacing: 0px;

    .table-head-row {
      background-color: $white;

      > * {
        font-size: 14px;
      }

      .MuiTableCell-root {
        padding: 10px;
        height: 40px;
        box-sizing: border-box;
        border-bottom: 2px solid $grey-4;
        border-top: 1px solid $grey-4;

        .sort-label {
          font-weight: bold;
          color: $grey-2;

          .sort-icon {
            width: 24px !important;
            max-width: 24px !important;
            min-width: 24px !important;
          }
        }
      }

      .MuiTableCell-root:first-child {
        border-left: 1px solid $grey-4;
        border-top-left-radius: 4px;
        padding-left: 15px;
      }

      .MuiTableCell-root:last-child {
        border-right: 1px solid $grey-4;
        border-top-right-radius: 4px;
      }
    }

    .table-body-row {
      &:nth-child(odd) {
        background-color: $grey-5;
      }

      &:nth-child(even) {
        background-color: $grey-3;
      }

      > * {
        font-size: 14px;
      }

      .icon-btn-root {
        padding: 0px;
      }

      .MuiTableCell-root {
        padding: 10px;
        height: 40px;
        box-sizing: border-box;
      }

      .MuiTableCell-root:first-child {
        border-left: 1px solid $grey-4;
        padding-left: 15px;
      }

      .MuiTableCell-root:last-child {
        border-right: 1px solid $grey-4;
      }

      .empty-row {
        > div {
          text-align: center;
        }
      }

      .loading-row {
        text-align: center;
      }

      .table-info-icon {
        font-size: 18px;
        cursor: help;
        color: $primary;
      }

      .table-edit-icon {
        font-size: 18px;
        cursor: pointer;
        color: $blue-1;
      }

      .table-remove-icon {
        font-size: 1.125rem;
        cursor: pointer;
        color: $error;
        margin-right: 5px;
      }

      .table-sent-icon {
        font-size: 1.125rem;
        cursor: pointer;
        color: $blue-1;
        margin-right: 5px;
      }

      .table-delete-icon {
        font-size: 18px;
        cursor: pointer;
        color: $error;
      }

      .status-chip {
        width: fit-content;
        min-width: 20px;
        height: 22px;
        display: flex;
        align-items: center;
        gap: 5px;

        .label-text {
          font-size: 12px;
          color: $white;
          border-radius: 4px;
          padding: 1px 5px;
          background-color: $complementory-2;

          &.complementory {
            background-color: $complementory;
          }

          &.primary {
            background-color: $primary;
          }

          &.processing {
            background-color: $processing;
          }

          &.holding {
            background-color: $holding;
          }

          &.queuedForTerminal {
            background-color: $queuedForTerminal;
          }

          &.sentToTerminal {
            background-color: $sentToTerminal;
          }

          &.holdingExpDate {
            background-color: $holdingExpDate;
          }

          &.completed {
            background-color: $completed;
          }

          &.success {
            background-color: $success;
          }

          &.error {
            background-color: $error;
          }

          &.expired {
            background-color: $expired;
          }
        }
      }

      .retry-btn {
        font-weight: bold;
        min-width: fit-content;
        height: fit-content;
        font-size: 14px;
      }

      .compartment-row-div {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .table-body-row-expanded {
      background-color: $grey-4;

      .table-body-row-expanded-label {
        font-size: 18px;
        font-weight: bold;
      }

      .table-body-row-expanded-list {
        .table-body-row-expanded-list-item {
          .table-body-row-expanded-list-item-label {
            font-size: 14px;
            font-weight: bold;
            color: $expired;
          }
        }
      }
    }
  }

  .table-pagination {
    height: 54px;
    background: $grey-5;
    border: 1px solid $grey-6;
    box-sizing: border-box;
    padding-left: 15px;
    display: flex;
    align-items: center;

    .pagination-items-root {
      > li {
        .Mui-selected,
        .MuiPaginationItem-previousNext {
          background-color: $primary;
          color: $white;
          border: none;
        }
      }
    }

    .paging-text {
      color: $grey-1;
      font-size: 14px;
    }

    .per-page-widget {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-left: 20px;
      font-size: 14px;
    }
  }
}

.summary-root {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.table-layout {
  .summary-charts {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Adjust sort label font size for the orders table */
.orders-table-layout {
  .sort-label {
    font-size: 88%;
  }

  .orders-row .MuiTableCell-root,
  .orders-cell {
    font-size: 88%;
  }
}



.orders-table {
  gap: 0px;

  .table-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .toggle-and-legends {
    display: flex;
    justify-content: end;
    gap: 20px;
    margin-top: 5px;

    .toggle {
      .toggle-title {
        font-size: 12px;
        font-weight: bold;
      }

      .MuiSwitch-root {
        .Mui-checked {
          .MuiSwitch-thumb {
            color: $primary;
          }
        }

        .MuiSwitch-track {
          &.checked {
            background-color: $complementory;
          }
        }
      }
    }

    .legends {
      display: flex;
      gap: 15px;
      border: 1px solid $grey-4;
      border-radius: 6px;
      padding: 0px 10px;

      .legend {
        display: flex;
        align-items: center;

        > * {
          font-size: 11px;
        }

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 3px;
          margin-right: 6px;

          &.complementory {
            background-color: $complementory;
          }

          &.complementory-2 {
            background-color: $complementory-2;
          }

          &.primary {
            background-color: $primary;
          }

          &.processing {
            background-color: $processing;
          }

          &.holding {
            background-color: $holding;
          }

          &.queuedForTerminal {
            background-color: $queuedForTerminal;
          }

          &.sentToTerminal {
            background-color: $sentToTerminal;
          }

          &.holdingExpDate {
            background-color: $holdingExpDate;
          }

          &.completed {
            background-color: $completed;
          }

          &.success {
            background-color: $success;
          }

          &.error {
            background-color: $error;
          }

          &.expired {
            background-color: $expired;
          }
        }
      }
    }
  }

  .filter-order-btn.disabled {
    background: $grey-9;
    cursor: not-allowed;
    pointer-events: none;
  }

  .filters {
    display: flex;
    align-items: center;
    gap: 10px;

    select {
      height: 30px;
    }

    .filter-order-btn {
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .chart-filters {
    display: flex;
    align-items: center;
    gap: 10px;

    .default-root {
      border: 1px solid $grey-4;
      min-height: 30px;
      display: flex;
      align-items: center;
      border-radius: 6px;

      .past-label {
        opacity: 0.6;
        font-weight: bold;
        margin-left: 15px !important;
      }

      .default {
        display: flex;
        gap: 15px;
        padding: 0px 15px;
      }

      .default {
        label {
          cursor: pointer;
          font-weight: bold;
          color: $grey-10;
        }
      }

      .filter-box {
        &.selected {
          label {
            color: $primary;
          }
        }
      }
    }

    .date-range-root {
      height: 30px;
      min-height: 30px;
      display: flex;
      align-items: center;
      border-radius: 6px;
      gap: 10px;

      .react-datepicker-wrapper {
        .react-datepicker__input-container {
          input {
            height: 30px;
            margin: 0px;
            width: 150px;
          }
        }
      }

      .date-filter-btn {
        height: 30px;
        padding: 0px;
        min-width: 80px;
      }
    }
  }

  .orders-table-main {
    margin-top: 20px;
  }
}

.d-error {
  color: $error;
  font-size: 14px !important;
  font-weight: 400 !important;
}

// Recharts Tooltip
.recharts-tooltip-wrapper {
  outline: none !important;

  .chart-content-tooltip-main {
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: 10px !important;
    min-width: 220px;

    &.chart-content-tooltip-pie {
      min-width: unset;
    }

    .chart-content-tooltip-title {
      label {
        color: $primary;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .chart-content-tooltip-content {
      display: flex;
      gap: 5px;
      flex-direction: column;

      .chart-content-tooltip-content-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        font-size: 14px;

        .chart-content-tooltip-content-row-key {
          text-align: left;
        }

        .chart-content-tooltip-content-row-value {
          font-weight: bold;
        }
      }
    }
  }
}

.recharts-xAxis {
  .recharts-label {
    transform: translate(0px, 35px) !important;
  }
}

/* Spinner */
.spinner {
  color: #077d95 !important;
}

.spinner.white {
  color: $white !important;
}

.swagger-ui .responses-header .response-col_status {
  width: 10% !important;
}

.swagger-ui .responses-header .response-col_description {
  width: 80% !important;
}

.swagger-ui .responses-header .response-col_links {
  width: 10% !important;
}

.swagger-ui .wrapper {
  max-width: 100% !important;
}

.d-dialog-root {
  .d-dialog-title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid $grey-4;
    padding: 16px 24px;
  }

  .d-dialog-content {
    padding: 16px 24px 24px 24px !important;
    border-bottom: 1px solid $grey-4;
  }

  .d-dialog-action {
    padding: 16px 24px;

    .btn.disabled {
      cursor: not-allowed;
      pointer-events: none;
      background-color: $grey-9;
    }
  }

  .add-domain-paper {
    height: 700px;
  }

  .add-driver-paper,
  .add-vehicle-paper {
    height: 700px;
  }
}

.details-block-selection-orders {
  &.form-field-box {
    label {
      margin-bottom: 0px !important;
    }
  }
}

.add-domain {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .terminal-email-notifications{
    font-size: 100%;
    font-weight: 600;
    margin-top: 2%;

    .details-block-selection-email {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 2%;
  
      input {
        width: fit-content;
        min-width: 17px;
      }
  
      input[type="checkbox"] {
        cursor: pointer;
      }
  
      label {
        font-size: 100%;
        font-weight:400 !important;
      }
  
      &.form-field-box {
        flex-direction: row;
  
        label {
          margin-bottom: 0% !important;
        }
      }
    }
    
  }
  .details-block-selection {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;

    input {
      width: fit-content;
      min-width: 17px;
    }

    input[type="checkbox"] {
      cursor: pointer;
    }

    label {
      font-size: 16px;
      font-weight: bold;
    }

    &.form-field-box {
      flex-direction: row;

      label {
        margin-bottom: 0px !important;
      }
    }
  }

  .details-block-selection-switch {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 0.5rem;

  
    input[type="checkbox"] {
      cursor: pointer;
    }

    label {
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .domain-details {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .proxy-domain-selected {
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    }

    .flag-selected {
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    }

    .details-block {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 10px;

      .domain-type-form {
        gap: 10px;
        display: flex;
        flex-direction: column;
        opacity: 0.5;
        pointer-events: none;
        cursor: default;

        &.selected {
          pointer-events: all;
          cursor: unset;
          opacity: 1;
        }

        .switch-box {
          flex-direction: row !important;
          align-items: center;

          .switch-label {
            font-size: 14px !important;
            margin-bottom: 0px !important;
          }

          .switch-label-text {
            font-size: 16px !important;
            margin-top: 8px !important;
          }
        }

        .public-host,
        .private-host {
          padding: 10px;
          border: 1px dotted $grey-10;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }

      .multi-emails-input {
        margin-top: 10px;
      }
    }

    .break-line {
      width: 1px;
      background-color: $grey-2;
    }
  }
}

.add-driver {
  display: flex;
  gap: 20px;
  flex-direction: column;

  .driver-details {
    .driver-primary-details {
      display: flex;
      gap: 20px;
      margin-bottom: 15px;

      .form-field-box {
        width: 100%;
      }
    }
  }

  .terminal-details {
    .terminal-details-title {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 10px;

      label {
        font-size: 16px;
        font-weight: 600;
      }

      .add-more-terminals {
        color: $complementory;
        cursor: pointer;
        width: 28px;
        height: 28px;
      }
    }

    .terminal-details-rows {
      max-height: 280px;
      overflow: auto;

      .terminal-row-main {
        margin-bottom: 15px;
        display: flex;
        gap: 20px;

        .tcn,
        .driver-id,
        .renewal-date {
          width: 30%;
        }

        .active {
          width: 10%;
          display: flex;
          align-items: center;

          .active-selection {
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.replay-order-root {
  .replay-order {
    display: flex;
    gap: 20px;

    .replay-left,
    .replay-right {
      width: 100%;

      .title {
        font-size: 18px;
        font-weight: bold;
        text-decoration: underline;
        text-align: center;
        display: block;
      }

      .current-values-form,
      .new-values-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    .replay-left {
      .title {
        color: $completed;
      }
    }

    .replay-right {
      .title {
        color: $complementory;
      }

      .new-values-form {
        .margin-bottom {
          margin-bottom: 23px;
        }
      }
    }

    .break-line {
      width: 1px;
      background-color: $grey-2;
    }
  }

  .product-codes {
    margin-top: 30px;

    .title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px !important;
      text-decoration: underline;
      display: inline-block;
    }

    .product-code-main {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
      align-items: baseline;

      .product-code-child {
        display: flex;
        align-items: center;
      }
    }
  }
}

.filter-orders-root {
  .row-filters,
  .date-filters {
    margin-bottom: 10px;
    display: flex;
    gap: 20px;

    > .form-field-box {
      width: 30%;
    }
  }

  .separator-div {
    margin: 20px 0px;
    width: 100%;
    height: 2px;
    background-color: $grey-4;
  }
}

.add-vehicle {
  .vehicle-details {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .vehicles-section {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .vehicles-section-title {
        display: flex;
        align-items: center;
        gap: 10px;

        > label {
          font-size: 16px;
          font-weight: bold;
        }

        .add-more-vehicles {
          color: $complementory;
          cursor: pointer;
          width: 28px;
          height: 28px;
        }
      }

      .vehicle-item-section {
        display: flex;
        flex-direction: column;
        gap: 12px;
        border: 1px solid $grey-9;
        padding: 10px 15px;

        &:nth-child(odd) {
          background: $grey-5;
        }

        &:nth-child(even) {
          background: $grey-7;
        }

        .vehicle-item-basic-details {
          display: flex;
          gap: 20px;

          > .form-field-box {
            width: 100%;
          }
        }

        .vehicle-item-compartment-details {
          display: flex;
          flex-direction: column;
          gap: 12px;
          padding: 0px 20px;
          max-height: 200px;
          overflow: auto;

          .vehicle-item-compartment-details-title {
            display: flex;
            align-items: center;
            gap: 10px;

            > label {
              font-size: 16px;
              font-weight: bold;
            }

            .add-more-compartments {
              color: $complementory;
              cursor: pointer;
              width: 28px;
              height: 28px;
            }
          }

          .compartment-main {
            display: flex;
            gap: 12px;

            > .form-field-box {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.swagger-dropdown {
  // width: fit-content;
  // flex-direction: row;
  // align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-top: 15px;

  .swagger-version-main {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .swagger-version-label {
    font-weight: 600 !important;
  }

  > label {
    margin-bottom: 0px !important;
    font-size: 20px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.swagger-dropdown-version {
  width: 200px;
}

.params-textfield {
  .param-pair {
    display: flex;
    margin-bottom: 5px;
  }

  .params-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
  }

  .margin-left-8 {
    margin-left: 8px !important;
  }

  .label-head {
    font-weight: 800;
  }
}

.proxy-domain-textfield {
  margin-top: 14px;

  .proxy-domain-pair {
    display: flex;
    margin-bottom: 5px;
  }

  .proxy-domain-pair-input-box {
    width: 100%;
  }

  .delete-proxy {
    margin-top: 20px;
  }

  .proxy-domain-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
  }

  .margin-left-8 {
    margin-left: 8px !important;
  }

  .label-head {
    font-weight: 800;
  }
}

.btn-clear {
  margin-left: 10px;
  background-color: rgb(184, 183, 183);
}

.swagger-ui .opblock.opblock-post {
  background-color: #e6f2ff !important;
  border-color: #0056b3 !important;

  .tab-header .tab-item.active h4 span:after {
    background: #00bbe7 !important;
  }

  .opblock-summary {
    border-color: #00bbe7 !important;
  }

  .opblock-summary-method {
    background-color: #4990e2 !important;
    border-color: #00bbe7 !important;
  }
}

.swagger-ui .opblock.opblock-get {
  background-color: #e6ffe6 !important;
  border-color: #1e7e34 !important;

  .tab-header .tab-item.active h4 span:after {
    background: #1e7e34 !important;
  }

  .opblock-summary {
    border-color: #1e7e34 !important;
  }

  .opblock-summary-method {
    background-color: #28a745 !important;
    border-color: #1e7e34 !important;
  }
}

.swagger-ui .opblock.opblock-delete {
  background-color: #ffe6e6 !important;
  border-color: #dc3545 !important;

  .tab-header .tab-item.active h4 span:after {
    background: #dc3545 !important;
  }

  .opblock-summary {
    border-color: #dc3545 !important;
  }

  .opblock-summary-method {
    background-color: #ff4d4f !important;
    border-color: #dc3545 !important;
  }
}

.swagger-ui .opblock.opblock-patch {
  background-color: #fff9db !important;
  border-color: #e0a800 !important;

  .opblock-summary {
    border-color: #e0a800 !important;
  }

  .tab-header .tab-item.active h4 span:after {
    background: #e0a800 !important;
  }

  .opblock-summary-method {
    background-color: #ffc107 !important;
    border-color: #e0a800 !important;
  }
}

.swagger-ui .opblock.opblock-put {
  background-color: #fff5d9 !important;
  border-color: #fd7e14 !important;

  .opblock-summary {
    border-color: #fd7e14 !important;
  }

  .tab-header .tab-item.active h4 span:after {
    background: #fd7e14 !important;
  }

  .opblock-summary-method {
    background-color: #fd7e14 !important;
    border-color: #e5670d !important;
  }
}

.swagger-ui .opblock .opblock-summary-description {
  font-weight: bolder;
}
