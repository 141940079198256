$white: #FFFFFF;
$black: #000000;

$grey-1: #999999;
$grey-2: #2E2E2E;
$grey-3: #F5F5F5;
$grey-4: #CACACA;
$grey-5: #FBFBFB;
$grey-6: #EDEDED;
$grey-7: #F1F1F1;
$grey-8: #C2C4C5;
$grey-9: #C0C0C0;
$grey-10: #656565;
$grey-11: #E6E6E6;
$grey-12: #B6B6B6;

$primary: #00BBE7;
$primary-2: #0B9585;

$blue-1: #3553C8;
$blue-2: #96C2FF;

$success: #8ACE33;
$error: #F18181;
$expired: #F53B3B;
$processing: #FEB42F;
$holding: #008080;
$holdingExpDate: #b6a934;
$completed: #2E8B57;
$queuedForTerminal: #C14444;
$sentToTerminal: #D35400;

$complementory: #5C950B;
$complementory-2: #953A0B;

$warning:#FFD187;

$box-shadow-1: rgba(0, 0, 0, 0.25);